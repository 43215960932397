import React from 'react';
import '../styles/HomeScreen.css'

const HomeScreen = () => {
    return (
        <div className='flex-row'>
            <div className='flex-column'>
                <img src='/highfivelogo.png' alt="The High Five logo"></img>
                <div className='text-top'>High Five</div>
            </div>
            <div className='flex-column'>
                <div className='background'>
                    <div className='flex-box padding-greybox'>
                        <div className='flex-row padding-text'>
                            <div className='text-header'>5-Minute Drone Delivery.</div>
                            <div className='text-description'>
                                High Five gives you an ecosystem of drone delivery tools, 
                                <br></br>
                                which include a delivery drone, the Loading dock, and a 
                                <br></br>
                                charging pad that can be made available to an ever 
                                <br></br>
                                expanding network of High Fivers.
                            </div>
                            <div className='text-grey'>High Five your customer.</div>
                            <div className="button">
                                <button> 
                                    <a href="https://4v7yp0s537h.typeform.com/to/vAkOvU2c" target="_blank" rel="noreferrer">Book Your First Delivery</a>
                                </button>
                            </div>
                        </div>
                        <div className = 'padding-image'>
                            <img src='/backyardDelivery.png' alt="A drone delivering a package in a backyard"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex-column padding-socialMedia'>
                <div>
                    <a href="https://www.instagram.com/highfive_here_/" target="_blank" rel="noreferrer">
                        <img src='/InstagramLogo.png' alt="Instagram Logo"></img>
                    </a>
                </div>
            </div>
        </div>
        
    )
}

export default HomeScreen;
