import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from './components/HomeScreen'

function App() {
  return (
    <Router>
    <div className="app">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
